<template>
  <card-chart
    :check="false"
    :title="'Số lượng sinh viên'"
    :sub-title1="'Tổng số SV'"
    :sub-title2="'Tổng số SV đang học'"
    :data-card="dataStudentOverview"
  />
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CardChart from "@/views/admin/category/charts/component/CardChart.vue";

export default {
  components: {
    CardChart,
    BDropdownItem, BDropdown,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      goal_overview_student: {
        completed: null,
        inProgress: null,
      },
      dataStudentOverview: {},
    }
  },
  computed: {
    ...mapGetters( {
      countStudent: 'chart/countStudent',
    }),
  },
  watch: {
    'filter.trainingSystemId': async function () {
      await this.getDataFromStore()
    },
    'filter.yearId': async function () {
      await this.getDataFromStore()
    },
    'filter.semesterId': async function () {
      await this.getDataFromStore()
    }
  },
  async created() {
    await this.getDataFromStore()
  },
  methods:{
    ...mapActions({
      getStudentOverview: 'chart/getStudentOverview',
    }),
    async getDataFromStore(){
      this.isLoading = true
      try {
        await this.getStudentOverview(this.filter)
        if (this.countStudent.length > 0) {
          this.goal_overview_student.completed = this.countStudent[0].countStudentSum != null ? this.countStudent[0].countStudentSum : 0
          this.goal_overview_student.inProgress = this.countStudent[0].countStudentCurrent != null ? this.countStudent[0].countStudentCurrent : 0
          this.dataStudentOverview = this.goal_overview_student
        }
        if (this.countStudent.length === 0) {
          this.goal_overview_student.completed = 0
          this.goal_overview_student.inProgress = 0
          this.dataStudentOverview = this.goal_overview_student
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  }
}
</script>
