<template>
  <card-chart
    :check="false"
    :title="'Số lượng Cán bộ/Giảng viên'"
    :sub-title1="'Tổng số CB/GV'"
    :sub-title2="'Tổng số CB/GV tại trường'"
    :data-card="dataEmployeeOverview"
  />
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CardChart from "@/views/admin/category/charts/component/CardChart.vue";

export default {
  components: {
    CardChart,
    BDropdownItem, BDropdown,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      goal_overview_employees: {
        completed: null,
        inProgress: null,
      },
      dataEmployeeOverview: {},
    }
  },
  computed: {
    ...mapGetters( {
      countEmployees: 'chart/countEmployees'
    }),
  },
  watch: {
    'filter.trainingSystemId': async function () {
      await this.getDataFromStore()
    },
    'filter.yearId': async function () {
      await this.getDataFromStore()
    },
    'filter.semesterId': async function () {
      await this.getDataFromStore()
    }
  },
  async created() {
    await this.getDataFromStore()
  },
  methods:{
    ...mapActions({
      getEmployeesOverview: 'chart/getEmployeesOverview'
    }),
    async getDataFromStore(){
      this.isLoading = true
      try {
        await this.getEmployeesOverview(this.filter)
        if (this.countEmployees.length > 0) {
          this.goal_overview_employees.completed = this.countEmployees[0].countEmployeesSum != null ? this.countEmployees[0].countEmployeesSum : 0
          this.goal_overview_employees.inProgress = this.countEmployees[0].countEmployeesCurrent != null ? this.countEmployees[0].countEmployeesCurrent : 0
          this.dataEmployeeOverview = this.goal_overview_employees
        }
        if (this.countEmployees.length === 0) {
          this.goal_overview_employees.completed = 0
          this.goal_overview_employees.inProgress = 0
          this.dataEmployeeOverview = this.goal_overview_employees
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  }
}
</script>
