<template>
  <b-card title="Tình hình xét duyệt số lượng SV được làm KLTN">
    <app-echart-bar
      :option-data="createStudentBySubjectTypeEchartBar"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    AppEchartBar,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      createStudentBySubjectTypeEchartBar: {
        xAxis: {
          type: 'category',
          data: [
            // 'Khoa Văn hóa - Du lịch',
            // 'Khoa Sư phạm Trung học',
            // 'Khoa Nông Lâm',
            // 'Khoa SP Tiểu học - Mầm non',
            // 'Khoa Ngoại ngữ - CNTT',
            // 'Khoa Kinh tế'
          ],
        },
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
        grid: {
          left: '40px',
          right: '30px',
          bottom: '30px',
        },
        series: [
          {
            name: 'KLTN',
            type: 'bar',
            stack: 'advertising',
            data: [
              // 15, 20, 34, 23, 19, 27
            ],
            barMaxWidth: '20%',
            barMinWidth: '10px',
            color: '#666ee8',
          },
          {
            name: 'Học phần thay thế KLTN',
            type: 'bar',
            stack: 'advertising',
            data: [
              // 12, 23, 15, 20, 34, 23
            ],
            barMaxWidth: '20%',
            barMinWidth: '10px',
            color: '#28dac6',
            itemStyle: {
              barBorderRadius: [ 10, 10, 0, 0 ],
            },
          },
        ],
      },
    }
  },
  watch: {
    'filter.trainingSystemId': async function () {
      await this.getDataFromStore()
    },
    'filter.yearId': async function () {
      await this.getDataFromStore()
    },
    'filter.semesterId': async function () {
      await this.getDataFromStore()
    }
  },
  computed: {
    ...mapGetters( {
      countStudentBySubjectType: 'chart/countStudentBySubjectType'
    }),
  },
  async created() {
    await this.getDataFromStore()
  },
  methods:{
    ...mapActions({
      getCountStudentBySubjectType: 'chart/getCountStudentBySubjectType'
    }),
    async getDataFromStore(){
      this.isLoading = true
      try {
        await this.getCountStudentBySubjectType(this.filter)
        if (this.countStudentBySubjectType.length > 0) {
          this.createStudentBySubjectTypeEchartBar.xAxis.data = []
          this.createStudentBySubjectTypeEchartBar.series[0].data = []
          this.createStudentBySubjectTypeEchartBar.series[1].data = []
          this.countStudentBySubjectType.forEach((item, key) => {
            this.createStudentBySubjectTypeEchartBar.xAxis.data.push(this.countStudentBySubjectType[key].departmentName)
            this.createStudentBySubjectTypeEchartBar.series[0].data.push(this.countStudentBySubjectType[key].kltn)
            this.createStudentBySubjectTypeEchartBar.series[1].data.push(this.countStudentBySubjectType[key].ttkl)
          })
        }
        
      } finally {
        this.isLoading = false
      }
    },
  }
}
</script>
